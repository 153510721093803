import LineGradient from "../components/LineGradient";
import useMediaQuery from "../hooks/useMediaQuery";
import { useForm } from "react-hook-form";
import { motion } from "framer-motion";

const Contact = () => {
  const {
    register,
    trigger,
    formState: { errors },
  } = useForm();

  const onSubmit = async (e) => {
    console.log("~ e", e);
    const isValid = await trigger();
    if (!isValid) {
      e.preventDefault();
    }
  };

  const isAboveLarge = useMediaQuery("(min-width: 1060px)");

  return (
    <section id="contact" className="contact pt-24">
      {/* HEADINGS */}
      {isAboveLarge ? (
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -500 },
            visible: { opacity: 1, x: 0 },
          }}
          className="md:w-2/5 mx-auto text-center"
        >
          <div>
            <p className="font-playfair font-semibold text-4xl">
              {/*<span className="text-yellow">CONTACT ME</span>*/}
              GET IN TOUCH
            </p>
            <div className="flex justify-center mt-2 mb-10">
              <LineGradient width="w-2/3" />
            </div>
            <div className="flex md:justify-end my-5"></div>
          </div>
        </motion.div>
      ) : (
        <div className="md:w-2/5 mx-auto text-center">
          <div>
            <p className="font-playfair font-semibold text-4xl">
              {/*<span className="text-yellow">CONTACT ME</span>*/}
              GET IN TOUCH
            </p>
            <div className="flex justify-center mt-2 mb-10">
              <LineGradient width="w-2/3" />
            </div>
            <div className="flex md:justify-end my-5"></div>
          </div>
        </div>
      )}

      {/* FORM */}
      <div className="md:flex md:justify-center justify-center  mt-5">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: -20 },
            visible: { opacity: 1, y: 0 },
          }}
          className="basis-1/2 mt-10 md:mt-0"
        >
          <form
            target="_blank"
            className="justify-center"
            onSubmit={onSubmit}
            //action="https://formsubmit.co/el/padawu"
            //action="https://formsubmit.co/jp@farresto.com"
            action="https://formsubmit.co/764448e4aa1e584db6e18bf80bb46167"
            method="POST"
          >
            <input
              className="w-full bg-grey font-semibold placeholder-opaque-black p-3 rounded-md text-dark-blue"
              type="text"
              //name="name"
              placeholder="NAME"
              {...register("name", {
                required: true,
                maxLength: 100,
              })}
            />
            {errors.name && (
              <p className="text-red mt-1">
                {errors.name.type === "required" &&
                  "(*) I should probably know your name."}
                {errors.name.type === "maxLength" &&
                  "(*) I doubt your name is longer than 100 characters."}
              </p>
            )}

            <input
              className="w-full bg-grey font-semibold placeholder-opaque-black p-3 mt-5 rounded-md text-dark-blue"
              type="text"
              //name="email"
              placeholder="E-MAIL"
              {...register("email", {
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              })}
            />
            {errors.email && (
              <p className="text-red mt-1">
                {errors.email.type === "required" &&
                  "(*) I kind of need your e-mail address to get you back."}
                {errors.email.type === "pattern" &&
                  "(*) Looks like an invalid e-mail address."}
              </p>
            )}

            <textarea
              className="w-full bg-grey font-semibold placeholder-opaque-black p-3 mt-5 rounded-md text-dark-blue"
              name="message"
              placeholder="MESSAGE"
              rows="4"
              cols="50"
              {...register("message", {
                required: true,
                maxLength: 3000,
              })}
            />
            {errors.message && (
              <p className="text-red mt-1">
                {errors.message.type === "required" && "(*) Nothing to say?"}
                {errors.message.type === "maxLength" &&
                  "(*) Try to fit your message within 3000 characters. Should be enough for first contact."}
              </p>
            )}
            {/*<input
              type="hidden"
              name="_next"
              value="https://yourdomain.co/thanks.html"
            />*/}

            <div>
              <button
                className="p-5 bg-darkest-grey font-semibold text-white mt-5 border border-white
              rounded-md block m-auto
              hover:bg-button-hover-grey hover:text-white transition
              active:bg-button-active-grey active:text-gray-300 active:border-gray-400
              duration-250"
                type="submit"
              >
                SUBMIT MESSAGE
              </button>
            </div>
          </form>
        </motion.div>
      </div>
    </section>
  );
};

export default Contact;
