import SocialMediaIcons from "../components/SocialMediaIcons";
import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";
import AnchorLink from "react-anchor-link-smooth-scroll";
import lineClass from "../components/Line.css";
//import Line from "../components/Line.jsx";
//import Tilt from "react-vanilla-tilt";
//import Tilt, { TiltOptions } from "vanilla-tilt";
//import Tilt from "react-vanilla-tilt";
//import LineGradient from "../components/LineGradient";

const draw = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: (i) => {
    const delay = 0.25 + i * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, type: "spring", duration: 1.5, bounce: 0 },
        opacity: { delay, duration: 0.01 },
      },
    };
  },
};

const Landing = ({ setSelectedPage }) => {
  const isAboveLarge = useMediaQuery("(min-width: 1060px)");
  return (
    <section id="home" className="md:flex md:justify-center md:h-full py-32">
      {/* IMAGE SECTION */}

      <div className=" z-10 mt-16 md:mt-36 mr-4 justify-center">
        {isAboveLarge ? (
          <div>
            <motion.img
              alt="profile"
              className="self-end hover:filter transition duration-250 z-10
                grayscale-0
                w-1/2 max-w-[400px] md:max-w-[600px]
                justify-end
                outline outline-offset-2 outline-color-white"
              src="assets/profile-pic.png"
              initial={{ scale: 0, x: "100%", borderRadius: "100%" }}
              whileHover={{ borderRadius: "5%", scale: 1.01 }}
              animate={{ rotate: 0, scale: 1, x: "100%" }}
              transition={{
                type: "spring",
                stiffness: 200,
                damping: 20,
              }}
            ></motion.img>
          </div>
        ) : (
          <img
            alt="profile"
            className="z-10 w-full max-w-[400px] md:max-w-[600px] rounded-full
              justify-items-center
            ring-2 ring-offset-2 ring-offset-dark-blue ring-white"
            src="assets/profile-pic.png"
          />
        )}
      </div>

      {/* MAIN TEXT */}
      <div className="z-30 basis-2/5 mt-12 md:mt-32 md: ml-6 justify-center">
        {/* HEADINGS */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
        >
          <p className="text-6xl font-playfair z-10 text-start md:text-start mt-7 justify-start">
            JP {""}
            {/*<span
              className="xs:relative xs:font-semibold z-20 xs:before:content-brush
        before:absolute before:-left-[48px] before:top-[27px] before:z-[-1] hover:saturate-150"
            >*/}
            <span>
              Maldonado{/*TODO: Erase Brush Image above*/}
              {/*<div className="flex justify-start ml-5 mt-1 mb-10">
              <LineGradient width="w-80"/>
              </div>*/}
              <motion.svg
                className="
                before:absolute before:-left-[48px] before:top-[27px] before:z-[-1]"
                width="400"
                height="300"
                initial="hidden"
                animate="visible"
              >
                <motion.line
                  className={lineClass}
                  x1="24"
                  y1="5"
                  x2="374"
                  y2="5"
                  stroke="white"
                  variants={draw}
                  custom={2}
                />
              </motion.svg>
            </span>
          </p>

          <p className="font-quicksand -mt-64 mb-7 text-xl text-start md:text-start justify-start">
            Hi there! Welcome to my repository of personal projects.
          </p>
          <p className="font-quicksand mt-4 mb-7 text-xl text-start md:text-start justify-start">
            Scroll down to see the stuff I've been working on.
          </p>
          <p className="font-quicksand mt-16 mb-12 text-xl text-start md:text-start justify-start">
            Want to talk? Use the Contact Form or LinkedIn:
          </p>
        </motion.div>

        {/* CALL TO ACTIONS */}
        <motion.div
          className="flex mt-16 justify-start  md:justify-center sm:grid sm:grid-cols-2 gap-4"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
        >
          <div>
            <AnchorLink
              onClick={() => setSelectedPage("contact")}
              href="#contact"
            >
              <button
                className="justify-start bg-white text-deep-blue rounded-sm py-3 px-4 font-semibold
                            hover:bg-highlight-blue hover:text-white hover:scale-110
                            transition duration-250 active:saturate-200"
              >
                Contact Form
              </button>
            </AnchorLink>
          </div>
          <div className="-ml-12">
            <SocialMediaIcons classInput="hover:saturate-200 hover:scale-110 transition duration-250 w-12" />
          </div>
        </motion.div>

        <motion.div
          className="flex justify-start md:justify-start"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
        >
          {/*<SocialMediaIcons classInput="hover:saturate-200 transition duration-250"/>*/}
        </motion.div>
      </div>
    </section>
  );
};

export default Landing;
