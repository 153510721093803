import { motion } from "framer-motion";
import Tilt from "react-vanilla-tilt";

const cardVariant = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1 },
};

const Card = ({ title, description, image, link }) => {
  return (
    <motion.div variants={cardVariant}>
      <div>
        <Tilt
          style={{
            backgroundColor: "#04040a",
            color: "white",
          }}
        >
          <div>
            <center>
              <a href={link} target="_blank" rel="noreferrer">
                <img
                  src={`../assets/${image}`}
                  alt={title}
                  className="border-l-emerald-100 "
                />
              </a>
            </center>
            <p className="text-2xl font-playfair text-center mt-2">{title}</p>
            <p className="mt-2 text-sm text-center mb-5">{description}</p>
          </div>
        </Tilt>
      </div>
    </motion.div>
  );
};

export default Card;
