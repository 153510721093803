import LineGradient from "../components/LineGradient";
import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";
import Card from "../components/Card";

const Games = () => {
  const isAboveLarge = useMediaQuery("(min-width: 1060px)");
  return (
    <section id="games" className="pt-36 pb-48">
      {/* HEADINGS */}
      {isAboveLarge ?
      <motion.div
        className="md:w-2/5 mx-auto text-center  my-auto"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -500 },
          visible: { opacity: 1, x: 0 },
        }}
      > 
        <div>
          <p className="font-playfair font-semibold text-4xl">GAMES</p>
          <div className="flex justify-center mt-2 mb-10">
            <LineGradient width="w-2/3" />
          </div>
        </div>
      </motion.div>
      :
      <div className="md:w-2/5 mx-auto text-center  my-auto"> 
        <div>
          <p className="font-playfair font-semibold text-4xl">GAMES</p>
          <div className="flex justify-center mt-2 mb-10">
            <LineGradient width="w-2/3" />
          </div>
        </div>
      </div>
      }

      {/* ARTS */}
      <div className="flex justify-center ">
        <motion.div
          className="sm:grid sm:grid-cols-3 gap-4 "
          //variants={container}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
          transition={{
            type: "spring",
            stiffness: 200,
            damping: 20,
            staggerChildren: 0.2
          }}
          //transition={{duration:0.5, staggerChildren: 0.2}}
          variants={{
            hidden: { opacity: 0, y: -20 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          {/* ROW 1 */}
          <Card
            title="Gravitators"
            description="Modern Twin Stick Shooter"
            image="Games_Gravitators _Capsule.png"
            link={"https://store.steampowered.com/app/1321890/Gravitators/"}
          />
          <Card
            title="Unannounced Music Project"
            description="I'll post it once it's more polished"
            image="Games_MusicProject.jpg"
            link={"https://www.insulargames.com"}
          />
          <Card
            title="Alacranman"
            description="An unfinished semi-NSFW joke game"
            image="Games_Alacranman.jpg"
            link={"https://www.insulargames.com"}
          />
          <Card
            title="More Games to come"
            description="Visual Novel"
            image="Games_TBD_Template.jpg"
            link={"https://www.insulargames.com"}
          />
          <Card
            title="More Games to come"
            description="Beat-em up?"
            image="Games_TBD_Template.jpg"
            link={"https://www.insulargames.com"}
          />
          <Card
            title="More Games to come"
            description="Turn-Based Combat?"
            image="Games_TBD_Template.jpg"
            link={"https://www.insulargames.com"}
          />
        </motion.div>
      </div>
    </section>
  );
};

export default Games;
