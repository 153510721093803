const SocialMediaIcons = ({ classInput }) => {
  const classValue = classInput;

  return (
    <div>
      <a
        
        href="https://www.linkedin.com/in/juanpablomaldonado/"
        target="_blank"
        rel="noreferrer"
      >
        {/*target: to open a new tab */}
        <img
          alt="linkedin-link"
          src="../assets/LinkedIn_logo_blue.png"
          className={`${classValue}`}
        />
      </a>
    </div>
  );
};

export default SocialMediaIcons;
