import LineGradient from "../components/LineGradient";
import { motion } from "framer-motion";
import useMediaQuery from "../hooks/useMediaQuery";
import Card from "../components/Card";
//import Tilt from "react-vanilla-tilt";
//import VanillaTilt from "react-vanilla-tilt";
//import { TiltOptions } from "vanilla-tilt";
//import VanillaTilt, { TiltOptions } from "vanilla-tilt";
//import Classes from "./Cards.css";

/*const container = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};*/

const Arts = () => {
  const isAboveLarge = useMediaQuery("(min-width: 1060px)");
  return (
    <section id="art" className="pt-36 pb-48">
      {/* HEADINGS */}
      {isAboveLarge ? (
        <motion.div
          className="md:w-2/5 mx-auto text-center"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -500 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <div>
            <p className="font-playfair font-semibold text-4xl">ARTWORK</p>
            <div className="flex justify-center mt-2 mb-10">
              <LineGradient width="w-2/3" />
            </div>
          </div>
        </motion.div>
      ) : (
        <div className="md:w-2/5 mx-auto text-center">
          <div>
            <p className="font-playfair font-semibold text-4xl">ARTWORK</p>
            <div className="flex justify-center mt-2 mb-10">
              <LineGradient width="w-2/3" />
            </div>
          </div>
        </div>
      )}
      {/* ARTS */}
      <div className="flex justify-center ">
        <motion.div
          className="sm:grid sm:grid-cols-3 gap-4 "
          //variants={container}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.5, staggerChildren: 0.2 }}
          variants={{
            hidden: { opacity: 0, y: -20 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          {/* ROW 1 */}
          <Card
            title="Alien Buddah"
            description=""
            image="Art_AlienPunkV2-03.jpg"
            link={"https://farresto.com"}
          />

          <Card
            title="Voxel Lamborghini"
            description=""
            image="Art_Car_Lambo.gif"
            link={"https://farresto.com"}
          />
          <Card
            title="Alien Buddahs Compilation"
            description=""
            image="AliensPresentation.gif"
            link={""}
          />
          <Card
            title="Clouds"
            description="Trying out Brushes"
            image="Art_Clouds.jpg"
            link={"https://farresto.com"}
          />
          <Card
            title="Voxel DeLorean"
            description=""
            image="Art_Car_Time.gif"
            link={"https://farresto.com"}
          />
          <Card
            title="Exoplanet"
            description="Mars distant cousin"
            image="Art_Planet.jpg"
            link={"https://farresto.com"}
          />
        </motion.div>
      </div>
    </section>
  );
};

export default Arts;
