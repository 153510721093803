import SocialMediaIcons from "../components/SocialMediaIcons";
import useMediaQuery from "../hooks/useMediaQuery";

const Footer = () => {
  const isAboveLarge = useMediaQuery("(min-width: 1060px)");

  return (
    <>
      {isAboveLarge ? (
        <footer className=" bg-darkest-grey border-t-2 border-white">
          <div className="w-10/12 mx-auto">
            <div className="sm:grid sm:grid-cols-3 gap-4">
              <div
                className="font-playfair font-semibold text-2xl text-white mb-4 mt-6 justify-self-center align-top"
                style={{ userSelect: "none" }}
              >
                JP Maldonado
              </div>

                <div className="mb-4 mt-6 justify-self-center align-top">
                  <SocialMediaIcons classInput="grayscale hover:grayscale-0 transition duration-500 w-8" />
                </div>

              <div className="font-playfair text-md text-white mb-6 mt-7 justify-self-center">
                © 2023{" "}
                <a
                  href="https://www.farresto.com"
                  className="hover:text-yellow lowercase"
                >
                  farresto.com{" "}
                </a>
                - All Rights Reserved
              </div>
            </div>
          </div>
        </footer>
      ) : (
        <footer className=" left-full right-full bottom-full  bg-darkest-grey w-[460px] h-[200px] border-t-2 border-white">
          <div className="w-5/6 mx-auto">
            <div className="sm:grid sm:grid-cols-3 gap-4 justify-items-center">
              <div
                className="font-playfair font-semibold text-2xl text-white mt-6 justify-self-center align-top px-20"
                style={{ userSelect: "none" }}
              >
                JP Maldonado
              </div>
              <center>
                <div className="align-bottom mt-3">
                  <SocialMediaIcons classInput={"flex justify-center grayscale hover:grayscale-0 transition duration-500"} />
                </div>
              </center>
              <div className="font-playfair text-md text-white mt-6 justify-self-center ">
                © 2023{" "}
                <a
                  href="https://www.farresto.com"
                  className="hover:text-yellow"
                >
                  farresto.com{" "}
                </a>
                - All Rights Reserved
              </div>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;
