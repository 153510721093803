import LineGradient from "../components/LineGradient";
import { motion } from "framer-motion";
import useMediaQuery from "../hooks/useMediaQuery";
import Card from "../components/Card";

const Writing = () => {
  const isAboveLarge = useMediaQuery("(min-width: 1060px)");
  return (
    <section id="writing" className="pt-36 pb-48">
      {/* HEADINGS */}
      {isAboveLarge ? (
        <motion.div
          className="md:w-2/5 mx-auto text-center"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -500 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <div>
            <p className="font-playfair font-semibold text-4xl">WRITING</p>
            <div className="flex justify-center mt-2 mb-10">
              <LineGradient width="w-2/3" />
            </div>
          </div>
        </motion.div>
      ) : (
        <div className="md:w-2/5 mx-auto text-center">
          <div>
            <p className="font-playfair font-semibold text-4xl">WRITING</p>
            <div className="flex justify-center mt-2 mb-10">
              <LineGradient width="w-2/3" />
            </div>
          </div>
        </div>
      )}

      {/* WRITING */}
      <div className="flex justify-center ">
        <motion.div
          className="sm:grid sm:grid-cols-3 gap-4 "
          //variants={container}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.5, staggerChildren: 0.2 }}
          variants={{
            hidden: { opacity: 0, y: -20 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          {/* ROW 1 */}
          <Card
            title="Early Retirement"
            description="A Short Gravitators Story"
            image="Writing_EarlyRetirement.jpg"
            link={"../assets/Early_Retirement_pdf_mobi_epub_azw3.zip"}
          />
          <Card
            title="More Stories to come"
            description="When the sun rises in the west and sets in the east. When the seas go dry and mountains blow in the wind like leaves."
            image="Writing_TBD_Template.jpg"
            link={"https://www.insulargames.com"}
          />
          <Card
            title="More Stories to come"
            description="When the sun rises in the west and sets in the east. When the seas go dry and mountains blow in the wind like leaves."
            image="Writing_TBD_Template.jpg"
            link={"https://www.insulargames.com"}
          />
          <Card
            title="More Stories to come"
            description="When the sun rises in the west and sets in the east. When the seas go dry and mountains blow in the wind like leaves."
            image="Writing_TBD_Template.jpg"
            link={"https://www.insulargames.com"}
          />
          <Card
            title="More Stories to come"
            description="When the sun rises in the west and sets in the east. When the seas go dry and mountains blow in the wind like leaves."
            image="Writing_TBD_Template.jpg"
            link={"https://www.insulargames.com"}
          />
          <Card
            title="More Stories to come"
            description="When the sun rises in the west and sets in the east. When the seas go dry and mountains blow in the wind like leaves."
            image="Writing_TBD_Template.jpg"
            link={"https://www.insulargames.com"}
          />
        </motion.div>
      </div>
    </section>
  );
};

export default Writing;
